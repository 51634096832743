<template>
  <div class="font-poppins flex flex-row rounded text-neutral-950 modern-color-theme" :class="computedClasses" data-component-name="VAlert">
    <VIcon :name="icon" :color="iconColor" />
    <div class="flex flex-col">
      <p v-if="props.title" class="text-sm leading-5 font-semibold" v-text="props.title" />
      <p class="text-sm leading-5 font-normal" v-text="props.message" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {computed} from "vue";
import VIcon from "../labels/VIcon.vue";
import type { Icon } from '@icons/index';
import type { ColorWithShade } from "../../utils/types";

defineOptions({
  name: 'VAlert'
})

type AlertType = 'info' | 'success' | 'warning' | 'error' | 'default'

const props = withDefaults(
  defineProps<{
    type?: AlertType,
    title?: string
    message: string
    dense?: boolean
  }>(), {
    type: 'default',
    title: '',
    dense: false
  }
)

const TYPES: Record<AlertType, { color: ColorWithShade, icon: Icon }> = {
  info: {
    icon: 'Solid/information-circle',
    color: 'primary/500'
  },
  success: {
    icon: 'Solid/check-circle',
    color: 'green/500'
  },
  warning: {
    icon: 'Solid/exclamation',
    color: 'yellow/500'
  },
  error: {
    icon: 'Solid/x-circle',
    color: 'red/500'
  },
  default: {
    icon: 'Solid/question-mark-circle',
    color: 'neutral/500'
  }
}

const icon = computed(() => TYPES[props.type].icon)
const iconColor = computed(() => TYPES[props.type].color)

const computedClasses = computed(() => ({
  'bg-primary-200': props.type === 'info',
  'bg-green-150': props.type === 'success',
  'bg-yellow-150': props.type === 'warning',
  'bg-red-150': props.type === 'error',
  'bg-neutral-150': props.type === 'default',
  'p-2 gap-2': props.dense,
  'p-4 gap-4': !props.dense
}))
</script>